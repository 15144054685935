<template>
  <div class="order">
    <div class="_title" v-if="title">我的订单</div>
    <div class="_content" :class="className">
      <v-card flat class="mt-4" min-height="350">
        <v-card-text class="pa-0">
          <div v-if="show">
            <div v-if="payFlag" class="pay">
              <div class="left">
                <img src="../../assets/img/icon/11.png" alt="" />
              </div>
              <div class="right">
                <div class="tips">
                  <span>支付成功！</span>
                </div>
              </div>
            </div>
            <div v-else class="pay">
              <div class="left">
                <img src="../../assets/img/icon/12.png" alt="" />
              </div>
              <div class="right">
                <div class="tips">
                  <span>支付失败！</span>
                </div>
              </div>
            </div>
          </div>
          <v-btn
            style="min-width: 0 !important; width: 300px; margin: 0 auto"
            color="success"
            block
            class="mt-4"
            @click="btnAction"
          >
            查看订单
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      show: false,
      payFlag: true,
    };
  },
  created() {
    this.getFlag()
   
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "width: 100%; margin: auto";
        case "sm":
          return "width: 50%; margin: auto";
        case "md":
          return "width: 60%; margin: auto";
        case "lg":
          return "width: 40%; margin: auto";
        case "xl":
          return "width: 40%; margin: auto";
      }
    },
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "tab-item",
    },
  },
  methods: {
     getFlag() {
      if (this.$route.query.order) {
         this.getOrder(this.$route.query.order);
      } else {
        this.show = true;
      }
    },
    btnAction() {
      if (this.$store.state.navigation) {
        this.$router.push({ path: "/payRecord" });
      } else {
        this.$router.push({ path: "/user", query: { num: 2 } });
      }
    },
     getOrder(orderId) {
       this.$request({
        url: "/order/get",
        method: "post",
        data: { orderId: orderId },
      }).then((res) => {
     
        if (res.code == 200) {
          if (res.data.payStatus == 1) {
                        this.payFlag = true;
          } else {
            this.payFlag = false;
          }
          this.show = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  padding: 0 10px;
  padding-bottom: 150px;
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .pay {
    margin: 0 auto;
    .left {
      width: 102px;

      text-align: center;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .right {
      margin-top: 30px;
      margin-bottom: 20px;
      .tips {
        text-align: center;
        margin-bottom: 10px;
        margin-left: 20px;
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
      }
    }
  }

  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
}
</style>
